.inside-header{
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 7px 0 13px 0;
}
.right-head-b{
  display: table-cell;
  vertical-align: middle;

  [dir="ltr"] & {
    text-align: right;
    padding-left: 6px;
  }
  [dir="rtl"] & {
    text-align: left;
    padding-right: 6px;
  }

}
.inside-right-head-b{
  display: inline-table;
  table-layout: fixed;
  min-width: 328px;
  max-width: 100%;
  [dir="ltr"] & {
    text-align: left;
  }
  [dir="rtl"] & {
    text-align: right;
  }
}
.user-icon-b{
  display: table-cell;
  vertical-align: middle;
  width: 40px;
  padding-right: 7px;
}
.user-image-block{
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  >img{
    display: block;
    width: 100%;
  }
}
.head-txt-logo{
  font-size: 26px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.head-txt-itm{
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(var(--text-color));
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1.18px;
  line-height: 24px;
  direction: ltr;
  //text-transform: capitalize;
}
.head-links-b{
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  [dir="ltr"] & {
    padding-left: 7px;
    text-align: right;
  }
  [dir="rtl"] & {
    padding-right: 7px;
    text-align: left;
  }
}
.head-links-item{
  position: relative;
  width: 34px;
  height: 34px;
  display: inline-flex;
  vertical-align: top;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 0 2px 6px 0 rgba(var(--skin-color-1), 0.5);
  background-color: rgba(var(--skin-color-14), 0.2);
  cursor: pointer;
  transition: background-color 240ms;
  [dir="ltr"] & {
    &:not(:first-child){
      margin-left: 14px;
    }
  }
  [dir="rtl"] & {
    &:not(:first-child){
      margin-right: 14px;
    }
  }
  &:hover{
    background-color: rgba(var(--skin-color-14), 0.7);
  }

  &.active{
    background-color: rgba(var(--skin-color-14), 0.7);
  }
  >span{
    font-size: 14px;
    color: rgb(var(--text-color));
  }
}
//notification start-------------------------
.notification-holder{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 1464px;
  padding: 0;
  margin: 0 auto;
  z-index: 1000;
}
.notification-list-w{
  position: absolute;
  top: 16px;
  width: 100%;
  display: flex;
  padding: 0 16px;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-direction: column;
  max-width: 425px;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }
}
.notify-item{
  width: 100%;
  min-height: 54px;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  box-shadow: 0 3px 6px 3px rgba(var(--skin-color-black), 0.32);
  background-color: rgb(var(--global-color));
  position: relative;
  margin-bottom: 6px;
  animation: 360ms notification-open ease-in-out;
  overflow: hidden;
  &:before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    height: 100%;
    z-index: 10;
    border-radius: 6px 0 0 6px;
  }
  &:after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(var(--skin-color-white), 0.21);
  }
  &.error {
    &:before {
      background-image: linear-gradient(to left, rgb(var(--skin-color-21)), rgb(var(--status-color-3)));
    }
  }
  &.accept{
    &:before {
      background-color: rgb(var(--skin-color-19));
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
@keyframes notification-open {
  0%{
    opacity: 0;
    transform: translate3d(0,-100%,0);
  }
  100%{
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}
.notify-i-text-holder{
  display: flex;
  align-items: center;
  position: relative;
  z-index: 10;
  flex: 1;
  min-width: 0;
}
.closed-n-holder{
  flex: 0 0 auto;
  padding: 0 10px;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  color: rgba(var(--text-color),1);
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.notify-icon-box{
  flex: 0 0 auto;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: rgba(var(--skin-color-white), 0.1);
  box-shadow: 0 1px 4px 1px rgba(var(--skin-color-black), 0.2);
  color: rgba(var(--text-color),1);
  font-size: 13px;
  [dir="ltr"] & {
    margin-right: 10px;
  }
  [dir="rtl"] & {
    margin-left: 10px;
  }
}
.n-text-v{
  flex: 1;
  font-size: 11px;
  line-height: 16px;
  color: rgba(var(--text-color),1);
  font-weight: 300;
  letter-spacing: 0.92px;
  min-width: 0;
  word-break: break-word;
  //overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
}
//notification END---------------------------
