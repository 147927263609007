/********************popup layout START***********************/
.popup-wrapper {
  width: 100%;
  height: 100%;
  max-height: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(var(--skin-color-1), 0.85);
  user-select: none;
  overflow: hidden;

  &.active {
    max-height: 100%;

    .inside-popup-wrapper {
      transform: translateY(100%);
    }
  }
}

.inside-popup-wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -100%;
  left: 0;
  transition: transform 240ms;
}

.for-inside-popup-wrapper {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  text-align: center;
}

.wr-popup-layout {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  max-width: 850px;
  max-height: 812px;
  background-color: rgb(var(--global-color));
  overflow: hidden;
}

.popup-layout {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  //background: url("../images/bg-repeat-img.png") repeat;
  width: 100%;
  height: 100%;
  text-align: left;
  overflow: hidden;
}

.top-block-of-popup {
  grid-area: top;
}

.bottom-block-of-popup {
  grid-area: bottom;
  position: relative;
}

/**********************user-popup START******************/
.wr-user-popup-layout {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  max-width: 565px;
  max-height: 482px;
  background-color: rgb(var(--global-color));
  overflow: hidden;
}

.user-popup-layout {
  display: inline-grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  //background: url("../images/bg-repeat-img.png") repeat;
  text-align: left;
  overflow: hidden;
}

.top-of-user-popup-layout {
  grid-area: top;
  position: relative;
}

.popup-profile-header {
  position: relative;
  width: 100%;
  height: 72px;
  padding: 0 72px;
  background-color: rgba(var(--skin-color-5), 0.7);
}

.popup-profile-name {
  font-size: 24px;
  line-height: 72px;
  color: rgb(var(--text-color));
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-profile-header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }

  > span {
    color: rgb(var(--text-color));
    font-size: 12px;

    &:hover {
      color: rgba(var(--text-color), 0.5);
    }
  }
}

.bottom-of-user-popup-layout {
  grid-area: bottom;
  position: relative;
}

.popup-profile-content {
  width: 100%;
  padding: 30px;
}

.popup-profile-avatar {
  width: 100%;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1.5px;
  font-weight: 500;
  color: rgb(var(--text-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  [dir="ltr"] & {
    text-align: left;
  }
  [dir="rtl"] & {
    text-align: right;
  }
}

.popup-profile-avatar-d {
  width: 100%;
  position: relative;
  padding: 0 35px;
  height: 146px;
}

.popup-profile-avatar-d-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.popup-profile-avatar-img {
  flex: 1;
  height: 100%;
  padding: 0 6px;

  &.active {
    img {
      transform: scale(1.2);
      box-shadow: 0 0 0 1px #ffffff;
    }
  }
}

.icon-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;

  > span {
    color: rgb(var(--text-color));
    font-size: 30px;
    cursor: pointer;
  }
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
    transform: rotateY(180deg);
  }
}

.icon-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  position: absolute;
  top: 0;

  > span {
    color: rgb(var(--text-color));
    font-size: 30px;
    cursor: pointer;
  }
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
    transform: rotateY(180deg);
  }
}

.popup-profile-avatar-img-flex {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > img {
    width: 100%;
    max-width: 70px;
    max-height: 70px;
    overflow: hidden;
    border-radius: 50%;
    transition: transform 240ms;
  }
}

.popup-profile-nichname-d {
  width: 100%;
  position: relative;
}





.popup-profile-nichname-input {
  width: 100%;
  margin-top: 20px;
  position: relative;

  &.inactive {
    pointer-events: none;
    .icon-block-inp {
      > span {
        opacity: 1;
      }
    }
  }


}

.popup-profile-nichname-input input:-webkit-autofill,
.popup-profile-nichname-input input:-webkit-autofill:hover,
.popup-profile-nichname-input input:-webkit-autofill:focus {
  -webkit-text-fill-color: rgba(var(--text-color), 0.7);
  -webkit-box-shadow: 0 0 0px 0px rgba(var(--skin-color-white), 0.08) inset;
  transition: background-color 5000s ease-in-out 0s;
}


.popup-profile-nichname-error-massage {
  position: absolute;
  left: 0;
  bottom: -28px;
  text-transform: capitalize;
  font-size: 12px;
  color: rgb(var(--text-color));
  line-height: 20px;

  &:before {
    content: "\e93b";
    font-family: BetConstruct-Icons !important;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    font-size: 18px;
    padding-right: 8px;
  }
}

.input-nichname {
  width: 100%;
  height: 66px;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-white), 0.08);
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  color: rgba(var(--text-color), 0.7);
  border: none;
  outline: none;
  [dir="ltr"] & {
    padding: 0 44px 0 20px;
  }
  [dir="rtl"] & {
    padding: 0 20px 0 44px;
  }

  &::-webkit-input-placeholder {
    letter-spacing: 1.33px;
    font-weight: 500;
    color: rgba(var(--text-color), 0.7);
  }
}

.icon-block-inp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 100%;
  position: absolute;
  top: 0;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }

  > span {
    font-size: 18px;
    color: rgb(var(--text-color));
    opacity: 0;
    transition: opacity 240ms;
  }

  >img{
    display: block;
    max-width: 100%;
    max-height: 100%;
    filter: brightness(0) invert(1);
  }
}

.popup-profile-button-d {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.popup-profile-button {
  min-width: 140px;
  max-width: 100%;
  height: 40px;
  padding: 0 4px;
  box-sizing: border-box;
  border-radius: 8px;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.4) 1%, rgba(var(--skin-color-white), 0.04));
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(var(--text-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border: none;
  outline: 0;

  &.active {
    background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.8), rgba(var(--skin-color-22), 0.8) 83%);
    color: rgb(var(--text-color));
  }

  &:hover{
    background-color: rgba(var(--skin-color-white), 0.14);
  }
}

/**********************user-popup END******************/
/********************popup layout END***********************/
/********************HELP START***********************/
.popup-title {
  width: 100%;
  height: 56px;
  text-align: center;
  position: relative;
  padding: 0 50px;
  box-shadow: 0 4px 8px 0 rgba(var(--skin-color-1), 0.016), 0 2px 4px 0 rgba(var(--skin-color-1), 0.16);
  background-color: rgba(var(--skin-color-white), 0.016);
  cursor: default;
}

.d-help-close {
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }
  > span {
    font-size: 12px;
    color: rgb(var(--text-color));

    &:hover {
      color: rgba(var(--text-color), 0.5);
    }
  }
}

.p-help {
  width: 100%;
  font-size: 16px;
  line-height: 56px;
  color: rgb(var(--text-color));
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
}

.popup-bottom-wr {
  width: 100%;
  padding: 20px;
}

.d-about-game {
  margin-top: 24px;
  [dir="ltr"] & {
    text-align: left;
  }
  [dir="rtl"] & {
    text-align: right;
  }

  &:first-child {
    margin-top: 0;
  }
}

.s-about {
  width: 100%;
  color: rgb(var(--text-color));
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 1px;
  word-break: break-word;
}

.p-about {
  width: 100%;
  color: rgb(var(--text-color));
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-top: 16px;
  letter-spacing: 1px;
  word-break: break-word;
  > p {
    margin-top: 16px;
  }
}

.d-max {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-black), 0.15);
  margin: 24px 0;

  &.max-prizes {
    margin-bottom: 0;
  }
}

.d-max-title-table-cell {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 1px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(var(--text-color));

  &:last-child {
    text-align: right;
  }
}

.d-max-title-table {
  display: table;
  width: 100%;
  table-layout: fixed;
  height: 40px;
  padding: 0 12px;
  background-color: rgba(var(--skin-color-white), 0.08);
}

.d-max-table {
  width: 100%;
  display: table;
  height: 34px;
  padding: 0 12px;
  background-color: rgba(var(--skin-color-white), 0.02);
}

.d-max-table-cell {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  color: rgb(var(--text-color));
  white-space: nowrap;

  &:first-child {
    max-width: 0;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    padding-right: 4px;
  }

  &:last-child {
    max-width: 100%;
    font-weight: 500;
    text-transform: uppercase;
  }

  > span {
    font-size: 10px;
    line-height: 14px;
    color: rgb(var(--text-color));
  }
}

/********************HELP END***********************/

/********************PRIZE START***********************/
.p-pl-about {
  color: rgb(var(--text-color));
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.17px;
  font-weight: 300;

  &.p-pl-about-f {
    margin-top: 16px;
  }
}

.d-box-place {
  width: 100%;
  min-height: 173px;
  margin-top: 18px;
  border-radius: 8px;
  padding: 65px 20px 20px 20px;
  text-align: center;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-1), 0.4);
  background-image: url("../images/place-bg.png");
  background-size: cover;

  &.box1-place {
    background-color: rgba(var(--skin-color-11), 0.5);
  }

  &.box2-place {
    background-color: rgba(var(--skin-color-10), 0.7);
  }

  &.box3-place {
    background-color: rgba(var(--skin-color-12), 0.2);
  }

  &.box4-place {
    background-color: rgba(var(--skin-color-9), 0.1);
  }
}

.max-tab {
  &:nth-child(odd) {
    background-color: rgba(var(--skin-color-white), 0.02);
  }

  &:nth-child(even) {
    background-color: rgba(var(--skin-color-white), 0);
  }
}

.s-pl-f {
  font-size: 40px;
  line-height: 42px;
  color: rgb(var(--text-color));
  font-weight: 600;
  text-transform: uppercase;
}

.p-pl-euro {
  font-size: 10px;
  line-height: 12px;
  color: rgba(var(--text-color), 0.65);
  font-weight: 300;
  margin-top: 30px;
}

/********************PRIZE END***********************/
/********************points-table START***********/
.point-rows-wr {
  width: 100%;
  padding: 16px 14px;
}

.point-row {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}

.point-item {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 300;
  line-height: 16px;
  color: rgb(var(--text-color));
  white-space: nowrap;

  &:first-child {
    text-transform: capitalize;
  }

  &:last-child {
    text-align: right;
    text-transform: uppercase;
  }
}

.total-point-bottom-wr {
  width: 100%;
  height: 46px;
  background-color: rgba(var(--skin-color-white), 0.06);
  position: relative;
}

.total-point-bottom-block {
  display: table;
  width: 100%;
  height: 100%;
  padding: 0 14px;
  position: absolute;
  top: 0;
  left: 0;
}

.total-point-bottom-item {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  font-weight: 500;
  color: rgb(var(--text-color));
  text-transform: uppercase;

  &:first-child {
    width: 100%;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:last-child {
    text-align: right;
    max-width: 100%;
    white-space: nowrap;
  }
}


/********************points-table END***********/
