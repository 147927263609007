@media screen and (min-width: 980px){
  .scroll-wr{
    [dir="ltr"] & {
      padding-right: 6px;
    }
    [dir="rtl"] & {
      padding-left: 6px;
    }
    &::-webkit-scrollbar {
      width: 4px;
      background: rgba(var(--skin-color-white), 0.2);
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background: rgba(var(--skin-color-white), 0.5)
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
  }
  .wr-popup-layout{
    border-radius: 8px;
  }
  .wr-user-popup-layout{
    border-radius: 8px;
  }

  .d-max{
    max-width: 575px;
    margin-left: auto;
    margin-right: auto;
  }
  .d-max-title-table{
    padding: 0 30px;
  }
  .d-max-table{
    padding: 0 30px;
  }
  .d-box-place{
    max-width: 575px;
    margin-left: auto;
    margin-right: auto;
  }
  .header-back-button {
    display: none;
  }
  .footer{
    .bet-btn-holder{
      display: none;
    }
  }
}

@media screen and (max-width: 1279px){
  .main-wrapper{
    padding: 6px;
  }
  .content-inside-wrapper{
    grid-gap: 6px;
  }
}
@media screen and (max-width: 979px) {
  .header-back-button span {
    font-size: 39px;
    color: rgb(var(--text-color));
  }
  .main-wrapper{
    padding: 0;
  }
  .mobile-nav{
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 50px;
    box-shadow: 0 9px 4px 11px rgba(var(--skin-color-1), 0.3);
    background-color: rgba(var(--skin-color-white), 0.3);
  }
  .nav-item{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    &.active{
      >span{
        color: rgb(var(--text-color));
      }
    }
    >span{
      font-size: 20px;
      color: rgba(var(--text-color),0.5);
      transition: color 240ms;
    }
    .table-statistics {
      position: relative;
    }
  }
  .content-inside-wrapper{
    width: 400%;
    grid-template-columns: repeat(4, 3fr);
    grid-column-gap: 0;
    transform: translate3d(0,0,0);
    transition: transform 240ms;
    &.step-1{
      [dir="ltr"] & {
        transform: translate3d(-25%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(25%,0,0);
      }
    }
    &.step-2{
      [dir="ltr"] & {
        transform: translate3d(-50%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(50%,0,0);
      }
    }
  }
  .left-container-wr{
    width: 100%;
    border-radius: 0;
    box-shadow: unset;
    background-color: transparent;
  }
  .inside-top-of-left-cont-wr{
    display: none;
  }
  .histories-wr{
    padding-top: 10px;
  }
  .tables-wr{
    padding-top: 10px;
  }
  .center-column{
    width: 100%;
  }
  .top-links-wrap{
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 16px 16px 12px 16px;
  }
  .top-links-block{
    display: table-cell;
    vertical-align: middle;
    font-size: 12px;
    line-height: 16px;
    color: rgba(var(--text-color),0.7);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: uppercase;
    transition: color 240ms;
    -webkit-user-select:none;
    -webkit-touch-callout:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    &:first-child{
      &:active{
        color: rgba(var(--text-color),0.7);
        font-size: 13px;
      }
    }
    &:last-child{
      text-align: right;
      &:active{
        color: rgb(var(--text-color));
        font-size: 13px;
      }
    }
    &.active{
      color: rgb(var(--text-color));
    }
  }
  .game-list-wr{
    grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
    grid-gap: 16px;
    padding: 0 16px 8px 16px;
    &.upcoming{
      padding-top: 8px;
    }
  }
  .bottom-your-prediction-item{
    &.center{
      width: 56px;
    }
  }
  .score-write-item{
    font-size: 21px;
  }
  .right-container-wr{
    width: 100%;
    border-radius: unset;
    box-shadow: unset;
    background-color: transparent;
  }
  /****************************header-START*************************************/
  .inside-header{
    padding: 12px 16px;
    box-shadow: 0 4px 8px 0 rgba(var(--skin-color-1), 0.6), 0 2px 4px 0 rgba(var(--skin-color-1), 0.2);
    background-color: rgba(var(--skin-color-white),0.05);
  }
  .head-logo-b{
    display: none;
  }

  .right-head-b{
    text-align: left;
  }
  .inside-right-head-b{
    display: flex;
    width: 100%;
    min-width: 0;
    justify-content: space-between;
    align-items: center;
  }
  .head-txt-b{
    display: none;
  }
  .full-sc-link{
    display: none;
  }
  /****************************header-END*************************************/
  .prize-row,.info-row{
    &.title{
      padding: 10px 12px;
    }
  }
  .popup-profile-name{
    font-size: 18px;
    font-weight: 400;
  }
  .popup-profile-avatar{
    font-size: 16px;
  }
  .input-nichname{
    height: 54px;
    font-size: 14px;
    [dir="ltr"] & {
      padding: 0 44px 0 16px;
    }
    [dir="rtl"] & {
      padding: 0 16px 0 44px;
    }
  }
  /*****************************loading-page******************************/
  .load-p-content{
    max-width: 80vw;
  }
  .loader-line-w{
    height: 4px;
    padding: 0;
  }
  .p-logo{
    font-size: 24px;
    line-height: 28px;
  }
  .maintenance-logo-block{
    max-width: 275px;
  }
  .maintenance-info-block{
    margin-top: 30px;
  }
  .maintenance-info-block-row{
    &:not(:last-child){
      margin-bottom: 12px;
    }
  }
  .maintenance-info-block-row-txt{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: .27px;
  }
  .maintenance-info-block-row-date{
    font-size: 18px;
    line-height: 24px;
    letter-spacing: .35px;
  }
  .powered-block{
    bottom: 55px;
  }
  .powered-txt{
    font-size: 12px;
    line-height: 16px;
  }
  .game-list-item{
    max-width: unset;
  }
  .bold-powered{
    font-size: 14px;
    line-height: 16px;
  }
  .center-block-layout{
    grid-template-rows: auto auto 10fr;
    grid-template-areas: "top" "bottom" "middle";
  }
  .buttons-wr{
    height: 44px;
    margin: 0;
    padding: 0 16px;
    background-color: transparent;
    box-shadow: none;
    .bet-btn-holder{
      display: none;
    }
  }
  .bet-btn-holder{
    max-width: unset;
    margin: 0;
  }
  .bet-btn{
    height: 45px;
    border-radius: 0;
    &:hover{
      background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.8), rgba(var(--skin-color-22),0.8) 83%);
    }
    &:active{
      background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.6), rgba(var(--skin-color-22),0.6) 83%);
    }
  }

  .game-list-btn{
    width: 28px;
    height: 28px;
    >span{
      font-size: 15px;
    }
    &:hover{
      background-color: rgba(var(--skin-color-white), 0.1);
    }
    &:active{
      background-color: rgba(var(--skin-color-white), 0.2);
    }
  }
  .share-wrapper{
    .game-list-item{
      max-width: 314px;
    }
  }
}
@media only screen and (device-width: 768px) and (orientation: portrait) {
  .game-info-cell{
    &:last-child{
      text-align: center;
    }
  }
  .buttons-wr{
    width: calc(100% - 74px);
    margin: 20px auto;
    height: 90px;
    padding: 0 24px;
    box-shadow: 0 5px 9px 2px rgba(var(--skin-color-black), 0.15);
    background-color: rgba(var(--skin-color-white), 0.08);
  }
  .game-list-btn{
    width: 50px;
    height: 50px;
    >span{
      font-size: 18px;
    }
    &:hover{
      background-color: rgba(var(--skin-color-white), 0.1);
    }
    &:active{
      background-color: rgba(var(--skin-color-white), 0.2);
    }
  }
  .buttons-wr{
    .bet-btn-holder{
      display: block;
      max-width: 270px;
      margin: 0 12px;
    }
    .bet-btn{
      height: 50px;
      border-radius: 9px;
    }
  }
  .footer{
    .bet-btn-holder{
      display: none;
    }
  }
  .center-block-layout {
    grid-template-rows: auto 10fr auto;
    grid-template-areas:
            "top"
            "middle"
            "bottom";
  }
  .game-list-item{
    max-width: 314px;
  }
}

@media screen and (max-width: 1184px) and (min-width: 1070px){
  .main-wrapper{
    width: 1070px;
  }
}

@media only screen and (max-width: 600px) {
  .s-pl-f{
    font-size: 35px;
  }
}

@media screen and (max-width: 1459px) and (min-width: 1421px)  {
  body{
    zoom: 0.98;
  }
}

@media screen and (max-width: 1420px) and(min-width: 1392px)  {
  body{
    zoom: 0.96;
  }
}

@media screen and (max-width: 1391px) and(min-width: 1363px)  {
  body{
    zoom: 0.94;
  }
}

@media screen and (max-width: 1362px) and(min-width: 1334px)  {
  body{
    zoom: 0.92;
  }
}

@media screen and (max-width: 1333px) and(min-width: 1305px)  {
  body{
    zoom: 0.90;
  }
}

@media screen and (max-width: 1304px) and (min-width: 1212px) {
  body{
    zoom: 0.88;
  }
}

@media screen and (max-width: 1211px) and (min-width: 1185px) {
  body{
    zoom: 0.86;
  }
}

@media screen and (max-width: 525px) {
  .popup-profile-nichname-error-massage {
    height: 40%;
    padding-left: 13px;
    text-indent:-13px;
  }
}

@media screen and (max-height: 568px) and (max-width: 979px) {
  .weekly-container{
    .inside-weekly-c-wr{
      overflow-y: auto;
      overflow-x: hidden;
    }
    .bottom-of-inside-weekly-c-wr{
      .scroll-wr{
        overflow: unset;
      }
    }
  }
}


// @media screen and (max-width: 1023px) and (orientation: landscape ){
//   html{
//     &:before {
//       position: fixed;
//       left: 0;
//       top: 0;
//       bottom: 0;
//       right: 0;
//       width: 100%;
//       height: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       text-align: center;
//       font: 38vw 'BetConstruct-Icons' !important;
//       color: rgba(#ffffff, 0.2);
//       content: "\e941";
//       z-index: 66;
//       transform: translate3d(0, 0, 0);
//     }
//     &:after {
//       position: fixed;
//       display: flex;
//       left: 50%;
//       top: 50%;
//       margin-top: -10rem;
//       margin-left: -10rem;
//       width: 20rem;
//       height: 20rem;
//       align-items: center;
//       justify-content: center;
//       text-align: center;
//       font: 15vw 'BetConstruct-Icons' !important;
//       color: rgba(#ffffff, 0.1);
//       content: "\e942";
//       z-index: 66;
//       transform: rotate(-90deg);
//     }
//   }
//   body {
//     &:before {
//       position: fixed;
//       display: flex;
//       left: 50%;
//       top: 50%;
//       margin-top: -10rem;
//       margin-left: -10rem;
//       width: 20rem;
//       height: 20rem;
//       align-items: center;
//       justify-content: center;
//       text-align: center;
//       font: 15vw 'BetConstruct-Icons' !important;
//       color: rgba(#ffffff, 0.5);
//       content: "\e942";
//       animation: 2200ms rotate-device ease-in-out forwards infinite;
//       z-index: 99;
//       transform: rotate(-90deg);
//     }
//     & > div{
//       opacity: 0;
//     }
//   }
//   @keyframes rotate-device{
//     0%{transform: rotate(-90deg); opacity: 0;}
//     60%{opacity: 1;}
//     90%{transform: rotate(0deg);opacity: 1;}
//     100%{transform: rotate(0deg);opacity: 0;}
//   }
// }





