.loading-p-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-image: linear-gradient(127deg, rgb(var(--skin-color-4)), rgb(var(--global-color)) 53%, rgb(var(--skin-color-8)));

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //background: url(../images/bg-repeat-img.png) repeat;
    pointer-events: none;
  }
}

.center-load-v {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.load-p-content {
  width: 100%;
  max-width: 50vw;
  text-align: center;
  padding: 20px 20px 40px;
}

.stars-b {
  width: 100%;
  padding: 0 0 60%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 755px;
    height: 446px;
    background-image: url(./../images/pakhlava.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    transform: translate(-50%, -41%);
    filter: blur(16px);
  }

  svg {
    position: absolute;
    top: 207px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}

.logo-l-v {
  width: 100%;
}

.p-logo {
  display: inline-block;
  vertical-align: top;
  margin-top: 5%;
  font-size: 32px;
  line-height: 36px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgb(var(--text-color));
  animation: 2s iris reverse;
}

@keyframes iris {
  0% {
    clip-path: circle(100% at 50% 50%);
  }
  25% {
    clip-path: circle(20% at 50% 50%);
  }
  50% {
    clip-path: circle(20% at 12% 84%);
  }
  75% {
    clip-path: circle(20% at 93% 51%);
  }
  100% {
    clip-path: circle(20% at -30% 20%);
  }
}

.m-logo {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin: auto;
  display: block;
  animation: 1s circle;
  animation-fill-mode: forwards;
  clip-path: circle(0%);
}

@keyframes circle {
  0% {
    clip-path: circle(0%);
  }
  100% {
    clip-path: circle(75%);
  }
}

.loader-line-w {
  margin: 5% 0 0;
  display: inline-block;
  vertical-align: top;
  width: 80%;
  height: 14px;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-black), 0.1);
  padding: 5px;
}

.line-v-box {
  height: 4px;
  border-radius: 3px;
  overflow: hidden;
  width: 100%;
}

.loader-line {
  width: 100%;
  height: 100%;
  background-color: rgb(var(--loader-color));
  border-radius: 3px;
  animation: 1000ms line-v linear infinite;
}

@keyframes line-v {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}

.powered-by {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: rgb(var(--text-color));
  font-weight: 300;
  letter-spacing: 1px;

  > p {
    > span {
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 1200px) {
  .load-p-content {
    max-width: 80vw;
  }
}

@media screen and (max-width: 1000px) {
  .stars-b {
    &:after {
      width: 313px;
      height: 150px;
      transform: translate(-50%, -58%);
    }

    svg {
      top: 19%;
    }
  }
}


.dNRdaTXM_0 {
  stroke-dasharray: 1729 1731;
  stroke-dashoffset: 1730;
}

.dNRdaTXM_0 {
  animation: dNRdaTXM_draw 666ms linear 0ms forwards;
}

.dNRdaTXM_1 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.dNRdaTXM_1 {
  animation: dNRdaTXM_draw 666ms linear 20ms forwards;
}

.dNRdaTXM_2 {
  stroke-dasharray: 209 211;
  stroke-dashoffset: 210;
}

.dNRdaTXM_2 {
  animation: dNRdaTXM_draw 666ms linear 41ms forwards;
}

.dNRdaTXM_3 {
  stroke-dasharray: 528 530;
  stroke-dashoffset: 529;
}

.dNRdaTXM_3 {
  animation: dNRdaTXM_draw 666ms linear 62ms forwards;
}

.dNRdaTXM_4 {
  stroke-dasharray: 12 14;
  stroke-dashoffset: 13;
}

.dNRdaTXM_4 {
  animation: dNRdaTXM_draw 666ms linear 83ms forwards;
}

.dNRdaTXM_5 {
  stroke-dasharray: 13 15;
  stroke-dashoffset: 14;
}

.dNRdaTXM_5 {
  animation: dNRdaTXM_draw 666ms linear 104ms forwards;
}

.dNRdaTXM_6 {
  stroke-dasharray: 17 19;
  stroke-dashoffset: 18;
}

.dNRdaTXM_6 {
  animation: dNRdaTXM_draw 666ms linear 125ms forwards;
}

.dNRdaTXM_7 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
}

.dNRdaTXM_7 {
  animation: dNRdaTXM_draw 666ms linear 145ms forwards;
}

.dNRdaTXM_8 {
  stroke-dasharray: 303 305;
  stroke-dashoffset: 304;
}

.dNRdaTXM_8 {
  animation: dNRdaTXM_draw 666ms linear 166ms forwards;
}

.dNRdaTXM_9 {
  stroke-dasharray: 11 13;
  stroke-dashoffset: 12;
}

.dNRdaTXM_9 {
  animation: dNRdaTXM_draw 666ms linear 187ms forwards;
}

.dNRdaTXM_10 {
  stroke-dasharray: 166 168;
  stroke-dashoffset: 167;
}

.dNRdaTXM_10 {
  animation: dNRdaTXM_draw 666ms linear 208ms forwards;
}

.dNRdaTXM_11 {
  stroke-dasharray: 82 84;
  stroke-dashoffset: 83;
}

.dNRdaTXM_11 {
  animation: dNRdaTXM_draw 666ms linear 229ms forwards;
}

.dNRdaTXM_12 {
  stroke-dasharray: 209 211;
  stroke-dashoffset: 210;
}

.dNRdaTXM_12 {
  animation: dNRdaTXM_draw 666ms linear 250ms forwards;
}

.dNRdaTXM_13 {
  stroke-dasharray: 528 530;
  stroke-dashoffset: 529;
}

.dNRdaTXM_13 {
  animation: dNRdaTXM_draw 666ms linear 270ms forwards;
}
.st0{fill:none;stroke:#cfcfcf;stroke-width:2;stroke-miterlimit:10;}
.dNRdaTXM_14 {
  stroke-dasharray: 12 14;
  stroke-dashoffset: 13;
}

.dNRdaTXM_14 {
  animation: dNRdaTXM_draw 666ms linear 291ms forwards;
}

.dNRdaTXM_15 {
  stroke-dasharray: 13 15;
  stroke-dashoffset: 14;
}

.dNRdaTXM_15 {
  animation: dNRdaTXM_draw 666ms linear 312ms forwards;
}

.dNRdaTXM_16 {
  stroke-dasharray: 74 76;
  stroke-dashoffset: 75;
}

.dNRdaTXM_16 {
  animation: dNRdaTXM_draw 666ms linear 333ms forwards;
}

@keyframes dNRdaTXM_draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dNRdaTXM_fade {
  0% {
    stroke-opacity: 1;
  }
  92.3076923076923% {
    stroke-opacity: 1;
  }
  100% {
    stroke-opacity: 0;
  }
}
