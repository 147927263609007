.color {
  position: fixed;
  //display: grid;
  display: none !important;
  grid-template-columns: repeat(16, 1fr);
  grid-gap: 2px;
  background-color: #ff0000;
  width: 100%;
  height: 100px;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.i {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(var(--text-color));
  &:nth-child(1){
    background-color: rgb(var(--skin-color-1));
  }
  &:nth-child(2){
    background-color: rgb(var(--skin-color-2));
  }
  &:nth-child(3){
    background-color: rgb(var(--skin-color-3));
  }
  &:nth-child(4){
    background-color: rgb(var(--skin-color-4));
  }
  &:nth-child(5){
    background-color: rgb(var(--skin-color-5));
  }
  &:nth-child(6){
    background-color: rgb(var(--skin-color-6));
  }
  &:nth-child(7){
    background-color: rgb(var(--global-color));
  }
  &:nth-child(8){
    background-color: rgb(var(--skin-color-8));
  }
  &:nth-child(9){
    background-color: rgb(var(--skin-color-9));
  }
  &:nth-child(10){
    background-color: rgb(var(--skin-color-10));
  }
  &:nth-child(11){
    background-color: rgb(var(--skin-color-11));
  }
  &:nth-child(12){
    background-color: rgb(var(--skin-color-12));
  }
  &:nth-child(13){
    background-color: rgb(var(--skin-color-13));
  }
  &:nth-child(14){
  background-color: rgb(var(--skin-color-14));
  }
  &:nth-child(15){
  background-color: rgb(var(--skin-color-15));
  }
  &:nth-child(16){
    background-color: rgb(var(--skin-color-16));
  }
}


.share-wrapper{
  //display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 1200px;
  max-width: 1200px;
  max-height: 630px;
  height: 630px;
  background-color: rgb(var(--global-color));
  &::before{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../images/bg-repeat-img.png") repeat;
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: url("../images/place-bg.png") repeat;
  }
}
.inside-share-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  >.game-list-item{
    animation: unset;
    opacity: 1;
    margin: 20px;
    &:nth-child(1):nth-last-child(4),&:nth-child(3):nth-last-child(2){
      margin-left: 157px;
    }
    &:nth-child(2):nth-last-child(3),&:nth-child(4):nth-last-child(1){
      margin-right: 157px;
    }
    /******* for 7 items********************/
    &:nth-child(1):nth-last-child(7), &:nth-child(1):nth-last-child(7) ~ .game-list-item{
      transform: scale3d(.76,.76,1);
      margin: 0;
    }
    &:nth-child(1):nth-last-child(7){
      bottom: 24px;
      left: 60px;
    }
    &:nth-child(2):nth-last-child(6){
      bottom: 24px;
    }
    &:nth-child(3):nth-last-child(5){
      bottom: 24px;
      right: 60px;
    }
    &:nth-child(4):nth-last-child(4){
      bottom: 75px;
      left: 60px;
    }
    &:nth-child(5):nth-last-child(3){
      bottom: 75px;
    }
    &:nth-child(6):nth-last-child(2){
      bottom: 75px;
      right: 60px;
    }
    &:nth-child(7):nth-last-child(1){
      bottom: 126px;
    }
    /******* for 8 items********************/
    &:nth-child(1):nth-last-child(8), &:nth-child(1):nth-last-child(8) ~ .game-list-item{
      transform: scale3d(.76,.76,1);
      margin: 0;
    }
    &:nth-child(1):nth-last-child(8),&:nth-child(2):nth-last-child(7),&:nth-child(3):nth-last-child(6),
    &:nth-child(5):nth-last-child(4),&:nth-child(6):nth-last-child(3),&:nth-child(7):nth-last-child(2){
      margin-left: -19px !important;
      left: 19px;
    }
    &:nth-child(1):nth-last-child(8),&:nth-child(2):nth-last-child(7),&:nth-child(3):nth-last-child(6),&:nth-child(4):nth-last-child(5){
      top: 35px;
    }
    &:nth-child(5):nth-last-child(4),&:nth-child(6):nth-last-child(3),&:nth-child(7):nth-last-child(2),&:nth-child(8):nth-last-child(1){
      bottom: 35px;
    }
    /******* for 9 items********************/
    &:nth-child(1):nth-last-child(9), &:nth-child(1):nth-last-child(9) ~ .game-list-item{
      transform: scale3d(.76,.76,1);
      margin: 0;
    }
    &:nth-child(1):nth-last-child(9),&:nth-child(2):nth-last-child(8),&:nth-child(3):nth-last-child(7){
      bottom: 20px;
    }
    &:nth-child(4):nth-last-child(6),&:nth-child(5):nth-last-child(5),&:nth-child(6):nth-last-child(4){
      bottom: 75px;
    }
    &:nth-child(7):nth-last-child(3),&:nth-child(8):nth-last-child(2),&:nth-child(9):nth-last-child(1){
      bottom: 130px;
    }
    &:nth-child(1):nth-last-child(9),&:nth-child(4):nth-last-child(6),&:nth-child(7):nth-last-child(3){
      left: 65px;
    }
    &:nth-child(3):nth-last-child(7),&:nth-child(6):nth-last-child(4),&:nth-child(9):nth-last-child(1){
      right: 65px;
    }
    /******* for 10 items********************/
    &:nth-child(1):nth-last-child(10), &:nth-child(1):nth-last-child(10) ~ .game-list-item{
      transform: scale3d(.76,.76,1);
      margin: 0;
    }
    &:nth-child(1):nth-last-child(10),&:nth-child(2):nth-last-child(9),&:nth-child(3):nth-last-child(8),
    &:nth-child(5):nth-last-child(6),&:nth-child(6):nth-last-child(5),&:nth-child(7):nth-last-child(4){
      margin-left: -19px !important;
    }
    &:nth-child(1):nth-last-child(10),&:nth-child(2):nth-last-child(9),&:nth-child(3):nth-last-child(8),&:nth-child(4):nth-last-child(7){
      bottom: 20px;
    }
    &:nth-child(5):nth-last-child(6),&:nth-child(6):nth-last-child(5),&:nth-child(7):nth-last-child(4),&:nth-child(8):nth-last-child(3){
      bottom: 75px;
    }
    &:nth-child(9):nth-last-child(2),&:nth-child(10):nth-last-child(1){
      bottom: 130px;
    }
    &:nth-child(2):nth-last-child(9),&:nth-child(6):nth-last-child(5){
      left: 45px;
    }
    &:nth-child(3):nth-last-child(8),&:nth-child(7):nth-last-child(4){
      right: 5px;
    }
    &:nth-child(1):nth-last-child(10),&:nth-child(5):nth-last-child(6){
      left: 95px;
    }
    &:nth-child(4):nth-last-child(7),&:nth-child(8):nth-last-child(3){
      right: 75px;
    }
    &:nth-child(9):nth-last-child(2){
      left: 36px;
    }
    &:nth-child(10):nth-last-child(1){
      right: 33px;
    }
  }
  .game-btn{
    >img{
      display: inline-block;
      vertical-align: top;
      height: 24px;
    }
    &.big{
      >img{
        width: 8px;
      }
    }
    &.small{
      >img{
        width: 14px;
      }
    }
  }
  .normal-spn-of-btn{
    padding-left: 2px;
    &::before{
      display: none;
    }
  }
}
