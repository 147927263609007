/*************************left-layout START**************/
.inside-left-container{
  width: 100%;
  height: 100%;
  position: relative;
}
.left-container-layout{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  // overflow: hidden;
  [dir="ltr"] & {
    &.next{
      .inside-underline{
        transform: translateX(100%);
      }
      .body-wr-left-cont{
        transform: translateX(-50.1%);
      }
    }
  }
  [dir="rtl"] & {
    &.next{
      .inside-underline{
        transform: translateX(-100%);
      }
      .body-wr-left-cont{
        transform: translateX(50.1%);
      }
    }
  }
}
.top-of-left-cont{
  grid-area: top;
  position: relative;
}
.body-of-left-cont{
  grid-area: bottom;
  position: relative;
  overflow: hidden;
  margin-top: 14px;
}
/*************************left-layout END**************/
/*****************************left top START*******************************/
.inside-top-of-left-cont-wr{
  width: 100%;
}
.tab-block-of-top-left{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  padding: 16px 0 12px 0;
  position: relative;
}
.tab-item-of-top-left-wr{
  flex: 1;
  position: relative;
}
.tab-item-of-top-left{
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
  color: rgba(var(--text-color),0.5);
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  transition: color 240ms;
  &.active-tab{
    color: rgb(var(--text-color));
  }
  &:hover{
    color: rgb(var(--text-color));
  }
}
.underline-block{
  width: 100%;
  height: 2px;
  border-radius: 2px;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.3), rgba(var(--skin-color-white),0.3)), linear-gradient(to bottom, rgba(var(--skin-color-white),0.3), rgba(#ababab,0.3));
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.inside-underline{
  display: block;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  border-radius: 2px;
  background-color: #A71F67;
  transition: transform 240ms;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
}
.name-table{
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 14px 12px 10px 12px;
}
.item-of-name-t{
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  color: rgba(var(--text-color),0.8);
  text-transform: capitalize;
  &:last-child{
    text-align: right;
  }
}
/*****************************left top END*******************************/

/*****************************left bottom START*******************************/
.body-wr-left-cont{
  display: grid;
  grid-template-columns: repeat(2, 6fr);
  grid-template-rows: 12fr;
  grid-gap: 0;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform 240ms;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
}
/*******************************history START**************************************/
.history-container{
  width: 100%;
  position: relative;
}
.histories-wr{
  width: 100%;
  padding-bottom: 6px;
}
.history-item-wr{
  position: relative;
  margin-bottom: 10px;
}
.history-item{
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  background-color: rgba(var(--rd-body-color), 1);
  //background-image: linear-gradient(to bottom, rgba(var(--rd-header-color), 0.02), rgba(255, 230, 243, 0.02)), linear-gradient(to bottom, rgba(var(--rd-header-color), 0.02), rgba(var(--rd-header-color), 0.02));
  cursor: pointer;
  // overflow: hidden;
  &:last-child{
    margin-bottom: 0;
  }
  &.selected{
    background-color: rgba(var(--rd-body-color), .7);
  }
  &.active{
    .share-block{
      width: 76px;
    }
    .history-game-info{
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &:hover{
    background-color: rgba(var(--rd-body-color), .5);
  }
  &.inactive{
    opacity: 0.65;
    pointer-events: none;
  }
  &.global{
    margin-bottom: 15px;
  }
  &.have-status{
    padding-top: 20px;
    .status-txt{
      border-radius: 6px 6px 0 0;
      display: block;
    }
    .share-block,.history-icon-block{
      padding-top: 20px;
    }
  }
}
.status-txt{
  display: none;
  width: 100%;
  height: 20px;
  font-size: 8px;
  line-height: 20px;
  letter-spacing: .27px;
  text-transform: uppercase;
  background-color: rgba(var(--rd-header-color), 1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 0;
  [dir="ltr"] & {
    padding-left: 12px;
    padding-right: 110px;
    left: 0;
  }
  [dir="rtl"] & {
    padding-left: 110px;
    padding-right: 12px;
    right: 0;
  }
}
.history-game-info{
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: rgb(var(--rd-name-color));
  letter-spacing: 0.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  [dir="ltr"] & {
    padding-left: 12px;
    padding-right: 110px;
  }
  [dir="rtl"] & {
    padding-left: 110px;
    padding-right: 12px;
  }
}
.history-icon-block{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 100%;
  position: absolute;
  top: 0;
  cursor: pointer;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }
  >span{
    color: rgba(var(--text-color),0.5);
    &.clickable{
      color: rgb(var(--text-color));
    }
  }
}
.share-icon{
  font-size: 14px;
}
.close-icon{
  font-size: 11px;
}
.share-block{
  position: absolute;
  top: 0;
  right: 38px;
  width: 0;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  cursor: default;
  overflow: hidden;
  transition: width 240ms;
  >a{
    margin-right: 10px;
  }
  >button{
    margin-right: 10px;
    &:last-child{
      margin-right: 0;
    }
  }
}
/*******************************history START**************************************/

/******************************table START****************************************/
.table-container{
  width: 100%;
  position: relative;
}
.tables-wr{
  width: 100%;
  padding-bottom: 6px;
}
.table-item{
  width: 100%;
  border-radius: 6px;
  margin-bottom: 6px;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.03)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.02), rgba(var(--skin-color-white), 0.02));
  cursor: pointer;
  &:hover,&.active{
    background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.05), rgba(var(--skin-color-16),0.05)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.05), rgba(var(--skin-color-white), 0.04));
  }
  &.active{
    .right-icon-block-of-t-t{
      >span{
        transform: rotateX(180deg);
      }
    }
    .table-body{
      animation: for-open-t-body 240ms ease-in;
    }
    @keyframes for-open-t-body {
      0%{
        max-height: 0;
      }
      100%{
        max-height: 940px;
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.table-title{
  width: 100%;
  padding: 12px 36px;
  position: relative;
}
.left-table-t-image-b{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
  >img{
    display: block;
    max-width: 45%;
    max-height: 45%;
    filter: var(--filter);
  }
}
.league-name{
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.47px;
  color: rgb(var(--text-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: uppercase;
  direction: ltr;
  [dir="ltr"] & {
    text-align: left;
  }
  [dir="rtl"] & {
    text-align: right;
  }
}
.right-icon-block-of-t-t{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 100%;
  position: absolute;
  top: 0;

  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }
  >span{
    font-size: 20px;
    color: rgb(var(--text-color));
    transition: transform 240ms;
  }
}
.table-body{
  width: 100%;
  max-height: 9999px;
  cursor: default;
  overflow: hidden;
  animation: for-close-t-body 240ms ease-out forwards;
}
@keyframes for-close-t-body {
  0%{
    max-height: 940px;
  }
  100%{
    max-height: 0;
  }
}
.game-info{
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 6px;
  cursor: default;
  &.title{
    padding: 6px 12px;
    margin-top: 6px;
    &:hover{
      cursor: default;
    }
    >.game-info-cell{
      color: rgba(var(--text-color),0.8);
      font-weight: 300;
      text-transform: uppercase;
      &:first-child{
        [dir="ltr"] & {
          padding-left: 0;
        }
        [dir="rtl"] & {
          padding-right: 0;
        }
      }
    }
  }

  &.item{
    padding: 12px;
    background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.02), rgba(var(--skin-color-16),0.02)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.01), rgba(var(--skin-color-white), 0.01));
    //background-color: rgb(var(--text-color));
    >.game-info-cell{
      color: rgb(var(--text-color));
      font-weight: 400;
      text-transform: capitalize;
      &:first-child{
        position: relative;
        [dir="ltr"] & {
          padding-left: 20px;
        }
        [dir="rtl"] & {
          padding-right: 20px;
        }
        >.number-itm{
          position: absolute;
          top: 0;
          [dir="ltr"] & {
            left: 0;
          }
          [dir="rtl"] & {
            right: 0;
          }
        }
      }
    }
  }
  &:last-child{
    margin-bottom: 0;
  }
}
.game-info-cell{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  font-weight: 500;
  padding: 0 4px;
  direction: ltr;
  &:first-child{
    width: 116px;
    letter-spacing: 0.4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    [dir="ltr"] & {
      text-align: left;
    }
    [dir="rtl"] & {
      text-align: right;
    }
  }
  &:last-child{
    letter-spacing: 0.4px;
    [dir="ltr"] & {
      text-align: right;
      padding-right: 0;
    }
    [dir="rtl"] & {
      text-align: left;
      padding-left: 0;
    }
  }
  &:nth-child(2){
    [dir="ltr"] & {
      padding: 0 4px 0 0;
    }
    [dir="rtl"] & {
      padding: 0 0 0 4px;
    }
  }
  &:nth-last-child(2){
    width: 50px;
    [dir="ltr"] & {
      padding: 0 0 0 6px;
    }
    [dir="rtl"] & {
      padding: 0 6px 0 0;
    }
  }
  &:nth-last-child(3){
    width: 54px;
    padding: 0;
  }
}
.number-itm{
  display: inline-block;
  vertical-align: top;
  width: 20px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: rgb(var(--text-color));
  word-break: break-all;
}
.small-game-table{
  display: table;
  table-layout: fixed;
  width: 100%;
  text-align: center;
}
.small-game-item{
  display: table-cell;
  vertical-align: middle;
}
/******************************table END****************************************/
/***************************left bottom END*********************************/
