.center-block-layout{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 10fr auto;
  grid-gap: 0;
  grid-template-areas: "top" "middle" "bottom";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.top-of-center-block{
  display: none;
  //grid-area: top;
  //width: 100%;
  //position: relative;
}
//.top-links-wrap{
//  display: none;
//}
.center-of-center-block{
  grid-area: middle;
  width: 100%;
  position: relative;
}
.game-list-wr{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(314px, 1fr));
  grid-gap: 28px;
  justify-items: center;
  grid-auto-rows: 1fr;
  padding: 0 8px 10px;
}
/*****************animation center-loading START*******/
.loader-d{
  position:absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin:auto;
}
.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  perspective: 800px;
  bottom: 0;
  right: 0;
  margin: auto;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0;
  top: 0;
  animation: rotate-one 1.5s linear infinite;
  border-bottom: 3px solid lighten(saturate(adjust-hue(#2b928a, -11), 9.28), 12.75);
}

.inner.two {
  right: 0;
  top: 0;
  animation: rotate-two 1.5s linear infinite;
  border-right: 3px solid lighten(saturate(adjust-hue(#2b928a, -11), 9.28), 12.75);
}

.inner.three {
  right: 0;
  bottom: 0;
  animation: rotate-three 1.5s linear infinite;
  border-top: 3px solid lighten(saturate(adjust-hue(#2b928a, -11), 9.28), 12.75);
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
/*****************animation center-loading END*******/
.game-list-item{
  width: 100%;
  max-width: 314px;
  height: 260px;
  position: relative;
  opacity: 0;
  perspective: 1000px;
  -moz-perspective: 1000px;
  animation: view 180ms forwards ease-in-out;
  &:nth-child(1){animation-delay: 300ms;}
  &:nth-child(2){animation-delay: 400ms;}
  &:nth-child(3){animation-delay: 500ms;}
  &:nth-child(4){animation-delay: 600ms;}
  &:nth-child(5){animation-delay: 700ms;}
  &:nth-child(6){animation-delay: 800ms;}
  &:nth-child(7){animation-delay: 900ms;}
  &:nth-child(8){animation-delay: 1000ms;}
  &:nth-child(9){animation-delay: 1100ms;}
  &:nth-child(10){animation-delay: 1200ms;}
  &:nth-child(11){animation-delay: 1300ms;}
  &:nth-child(12){animation-delay: 1400ms;}
  &:nth-child(13){animation-delay: 1500ms;}
  &:nth-child(14){animation-delay: 1600ms;}
  &:nth-child(15){animation-delay: 1700ms;}
  &:nth-child(16){animation-delay: 1800ms;}
  &:nth-child(17){animation-delay: 1900ms;}
  &:nth-child(18){animation-delay: 2000ms;}
  &:nth-child(19){animation-delay: 2100ms;}
  &:nth-child(20){animation-delay: 2200ms;}
  &:nth-child(21){animation-delay: 2300ms;}
  &:nth-child(22){animation-delay: 2400ms;}
  &:nth-child(23){animation-delay: 2500ms;}
  &:nth-child(24){animation-delay: 2600ms;}
  &:nth-child(25){animation-delay: 2700ms;}
  &:nth-child(26){animation-delay: 2800ms;}
  &:nth-child(27){animation-delay: 2900ms;}
  &:nth-child(28){animation-delay: 3000ms;}
  &:nth-child(29){animation-delay: 3100ms;}
  &:nth-child(30){animation-delay: 3200ms;}
  &.status-1{
    .bottom-line{
      &::after {
        height: 3px;
        background-image: linear-gradient(to top, rgb(var(--status-color-1)), rgb(var(--skin-color-19)) -17%);
        // background-image: linear-gradient(to top, $status-color-1, lighten(saturate($status-color-1, 45.50), 37.65) -17%);
      }
    }
  }
  &.status-2{
    .bottom-line {
      &::after {
        height: 3px;
        background-image: linear-gradient(to bottom, rgb(var(--skin-color-20)), rgb(var(--status-color-2)));
        // background-image: linear-gradient(to bottom, lighten(saturate(adjust-hue($status-color-2, 10), 3.67), 14.51), $status-color-2);
      }
    }
  }
  &.status-3{
    .bottom-line{
      &::after {
        height: 3px;
        background-image: linear-gradient(to bottom, rgb(var(--skin-color-21)) 16%, rgb(var(--status-color-3)) 84%);
        // background-image: linear-gradient(to bottom, lighten(saturate(adjust-hue($status-color-3, -3), 13.64), 28.63) 16%, rgb(var(--status-color-3)) 84%);
      }
    }
  }
  &.active{
    .for-change{
      animation: show 480ms ease-in forwards;
    }
    @keyframes show {
      0%{
        opacity: 0;
        max-width: 0;
        max-height: 0;
      }
      50%{
        opacity: 0;
        max-width: 100%;
        max-height: 100%;
      }
      100%{
        opacity: 1;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .center-circle{
      animation: appear-circle 120ms 480ms ease-in-out forwards;
    }
    @keyframes appear-circle {
      0%{
        transform: scale(0);
      }
      25%{
        transform: scale(1.15);
      }
      100%{
        transform: scale(1);
      }
    }
  }
  &.for-backspace{
    .inside-game-list-item{
      transform: rotateY(180deg);
    }
    .inside-game-list-history-wr{
      transform: rotateY(360deg);
    }
  }
}
@keyframes view {
  0% {clip-path: inset(50%);}
  99% {clip-path: inset(0%); opacity: 1}
  100% {clip-path: initial; opacity: 1}
}
.for-game-list-item{
  width: 100%;
  height: 100%;
  position: relative;
}
.inside-game-list-item{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  border-radius: 8px;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-black), 0.1);
  //background-color: rgba(var(--skin-color-white), 0.15);
  background-color: rgba(var(--ticket-bg-color), 1);
  transition: transform 240ms;
}
.for-top-of-inside-game-list-i{
  grid-area: top;
  position: relative;
}
.top-of-inside-game-list-i{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 50px;
  padding: 0 14px;
  border-radius: 8px 8px 0 0;
  background-color: rgba(var(--ticket-header-color), 1);
  direction: ltr;
}
.top-item-of-game-list-i{
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgb(var(--text-color));

  &:last-child{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: right;
  }
}
.statistic-icon-wr {
  width: 30px;
  padding: 5px;
  position: relative;
}
.statistic-icon{
  font-size: 12px;
  color: rgb(var(--text-color));
  cursor: pointer;
}
.top-game-i-icon{
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  margin-right: 8px;
  color: rgb(var(--text-color));
  cursor: pointer;
}
.flip-wr {
  display: inline-block;
}
.top-pts-spn{
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  color: rgb(var(--text-color));
}
.for-bottom-of-inside-game-list-i{
  grid-area: bottom;
  position: relative;
}
.bottom-inside-game-list-i-wr{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 11fr auto;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
}
.top-bottom-inside-game-list{
  grid-area: top;
  position: relative;
}
.inside-top-bottom-inside-game-list{
  width: 100%;
  height: 100%;
  position: relative;
}
.text-about-games{
  width: 100%;
  padding: 0 14px;
  position: absolute;
  top: 18px;
  left: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.6px;
  text-align: center;
  text-transform: uppercase;
  color: rgb(var(--text-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 1;
  &+.score-table-wr{
    padding-top: 42px;
  }
}
.score-table-wr{
  width: 100%;
  height: 100%;
  position: relative;
}
.inside-score-table-wr{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 0 10px 8px 10px;
}
.score-table-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 0 4px;
}
.team-logo-block{
  width: 100%;
  height: 100%;
  position: relative;
  >img{
    display: block;
    max-width: 60px;
    max-height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
.score-select-block{
  width: 100%;
  height: 54px;
  position: relative;
  border-radius: 6px;
  box-shadow: inset 0 10px 14px 0 rgba(22, 28, 72, 0.2);
  background-color: rgba(var(--skin-color-8), 0.2);
  // overflow: hidden;
  &:after{
    content: "";
    display: block;
    width: calc(100% - 4px);
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgba(var(--skin-color-white), 0.2);
    border-radius: 0 0 6px 6px;
  }
}
.inside-score-select-block{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  box-shadow: inset 0 10px 14px 0 rgba(var(--skin-color-1), 0.6)
}
.score-write-bl{
  display: table-cell;
  vertical-align: middle;
  position: relative;
  &:nth-child(2){
    width: 4px;
  }
}
.score-write-item{
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: rgb(var(--text-color));
  padding: 0;
  border: none;
  background: none;
}
.score-line{
  display: block;
  margin: 0 auto;
  width: 2px;
  height: 24px;
  background-color: rgb(var(--skin-color-white));
}
.team-name-block{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 42px;
  padding: 0 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  &+.inside-score-table-wr{
    padding-bottom: 50px;
  }
}
.team-name-item{
  display: table-cell;
  vertical-align: top;
  padding: 0 4px;
}
.team-name-spn{
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 18px;
  color: rgb(var(--text-color));
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.game-btn{
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  min-width: 28px;
  height: 24px;
  padding: 0 4px;
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(var(--skin-color-white), 0.75) inset;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.35) 1%, rgba(var(--skin-color-white),0.5) 74%);
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(var(--text-color));
  > img {
    filter: var(--filter);
  }
  &:hover{
    box-shadow: 0 2px 4px 0 rgba(var(--skin-color-white), 0.5) inset;
    background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.2) 1%, rgba(var(--skin-color-white),0.4) 74%);
  }
  &.state-1{
    box-shadow: inset 0 2px 4px 0 rgba(var(--skin-color-white), 0.5);
    background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.8), rgba(var(--skin-color-22),0.8) 83%);
    // background-image: linear-gradient(to top, rgba($brand-color-1, 0.8), rgba(lighten(saturate($brand-color-1, 45.50), 37.65),0.8) 83%);

    // color: set-color(var(--brand-color-1));
    color: rgb(var(--text-color));
  }
  &.state-2{
    background-image: linear-gradient(to bottom, lighten(saturate(adjust-hue(#fba429, 10), 3.67), 14.51), rgb(var(--brand-color-2)));
    //background-image: linear-gradient(to bottom, lighten(saturate(adjust-hue(#fba429, 10), 3.67), 14.51), $brand-color-2);

    color: rgb(var(--text-color));
  }
  &.inactive{
    &:hover{
      cursor: default;
    }
  }
}
.double-score-btn-wr{
  position: relative;
}
.double-score-btn {
  overflow: initial;
}
.invisible-plus{
  position: absolute;
  height: 24px;
  width: 28px;
  right: 14px;
  bottom: 12px;
}
/************************icons or text style in game-button START***************/
.small-spn-of-btn{
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
  line-height: 24px;
  color: rgb(var(--text-color));
  margin-right: 1px;
}
.normal-spn-of-btn{
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 24px;
  padding-left: 8px;
  position: relative;
  &:before{
    font-family: 'BetConstruct-Icons' !important;
    content: "\e901";
    font-size: 7px;
    line-height: 1;
    color: rgb(var(--text-color));
    display: block;
    position: absolute;
    top: 9px;
    left: 0;
  }
}
.icon-none{
  font-size: 14px;
  color: rgb(var(--text-color));
}
.icon-plus{
  font-size: 12px;
  line-height: 24px;
  color: rgb(var(--text-color));
}
.twitter-icon{
  font-size: 20px;
  line-height: 24px;
  color: rgb(var(--text-color));
}
.facebook-icon{
  font-size: 20px;
  line-height: 24px;
  color: rgb(var(--text-color));
}
.btn-x-icon{
  font-size: 8px;
  color: rgb(var(--text-color));
}
/************************icons or text style in game-button END***************/
.bottom-bottom-inside-game-list{
  grid-area: bottom;
  position: relative;
}
.inside-bottom-bottom-inside-game-list{
  width: 100%;
  height: 46px;
  border-radius: 8px;
  //background-color: rgba(var(--skin-color-white), 0.06);
  background-color: rgba(var(--ticket-header-color), 1);
  padding: 0 14px;
}
.team-icons-bottom-block{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 0;
}
.team-icon-item-wr{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &:first-child{
    padding-right: 4px;
    >.team-icon-b{
      >img{
        left: 0;
      }
    }
  }
  &:nth-child(2){
    text-align: center;
  }
  &:last-child{
    padding-left: 4px;
    >.team-icon-b{
      >img{
        right: 0;
      }
    }
  }
}
.team-icon-b{
  max-width: 100%;
  height: 100%;
  position: relative;
  >img{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}
.team-choose-bottom-block{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 0;
}
.team-choose-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &:first-child{
    padding-right: 4px;
  }
  &:last-child{
    width: 40px;
    text-align: right;
  }
}
.team-choose-txt{
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  font-weight: 300;
  color: rgb(var(--text-color));
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bottom-line{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: 8px;
  overflow: hidden;
  &::after{
    content: "";
    display: block;
    width: 100%;
    height: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background: blue;
  }
}
.bottom-your-prediction{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 0;
}
.bottom-your-prediction-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &.center{
    width: 70px;
  }
  &:last-child{
    [dir="ltr"] & {
      text-align: right;
    }
    [dir="rtl"] & {
      text-align: left;
    }
  }
  &:nth-child(2):nth-last-child(1) {
    width: 62px;
  }
}

.no-prediction{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.pred-txt{
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: rgb(var(--text-color));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.score-info-wr{
  width: 100%;
  text-align: center;
  font-size: 0;
}
.score-info-spn{
  margin: 0 2px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: rgb(var(--text-color));
}
.right-icons-table-of-bottom-pred{
  display: inline-table;
  vertical-align: top;
  table-layout: fixed;
  max-width: 100%;
  min-width: 62px;
  height: 100%;
}
.right-icon-item-of-bottom-pred{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  padding: 0 4px;
}
.bottom-team-icon-block-of-pred{
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  min-width: 30px;
  height: 100%;
  position: relative;
  >img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    &:hover{
      cursor: default;
    }
  }

}
/************************************************last-game styles START**********************/

.inside-game-list-history-wr{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  border-radius: 8px;
  box-shadow: 0 5px 9px 2px rgba(4, 9, 24, 0.15);
  background-color: rgba(var(--skin-color-white), 0.15);
  overflow: hidden;
  transition: transform 240ms;
}
.for-top-of-inside-game-list-history-wr{
  grid-area: top;
  position: relative;
}
.top-of-last-list{
  width: 100%;
  height: 50px;
  position: relative;
  background-color: rgba(var(--skin-color-white), 0.06);
  padding: 0 40px;
}
.lef-icon-bl-of-top-last-l{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.back-icon-in-history{
  font-size: 20px;
  color: rgb(var(--text-color));
}
.for-text-of-top-of-last-list{
  width: 100%;
  height: 100%;
  position: relative;
}
.text-of-top-of-last-list{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 50px;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.for-bottom-of-inside-game-list-history-wr{
  grid-area: bottom;
  position: relative;
  &.inactive{
    display: none;
  }
}
.inside-for-bottom-of-inside-game-list-history-wr{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 11fr auto;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
}
.top-inside-for-bottom-of-inside-game-list-history-wr{
  grid-area: top;
  position: relative;
}
.body-wr-of-last-game-list{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 11fr;
  grid-template-areas: "top" "bottom";
  grid-gap: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  &.step-1{
    .table-item-of-team-name{
      &:first-child{
        color: rgb(var(--text-color));
      }
    }
    .team-inside-underline{
      [dir="ltr"] & {
        transform: translate3d(100%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(-100%,0,0);
      }
    }
  }
  &.step-2{
    .table-item-of-team-name{
      &:nth-child(2){
        color: rgb(var(--text-color));
      }
    }
    .team-inside-underline{
      [dir="ltr"] & {
        transform: translate3d(200%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(-200%,0,0);
      }
    }
  }
  &.step-3{
    .table-item-of-team-name{
      &:nth-child(3){
        color: rgb(var(--text-color));
      }
    }
    .team-inside-underline{
      [dir="ltr"] & {
        transform: translate3d(300%,0,0);
      }
      [dir="rtl"] & {
        transform: translate3d(-300%,0,0);
      }
    }
  }
}
.top-of-body-wr-of-last-game-list{
  grid-area: top;
  position: relative;
}
.table-of-team-name{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  padding: 10px 0 10px 0;
  position: relative;
}
.table-item-of-team-name{
  flex: 1;
  padding: 0 4px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(var(--text-color),0.5);
  text-align: center;
  cursor: pointer;
  transition: color 240ms;
  &:hover{
    color: rgb(var(--text-color));
  }
}
.table-team-underline-block{
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 2px;
  left: 0;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white), 0.3), rgba(var(--skin-color-white), 0.3)), linear-gradient(to bottom, rgba(var(--skin-color-white), 0.3), rgba(171, 171, 171, 0.3));
}
.team-inside-underline{
  display: block;
  position: absolute;
  top: 0;

  width: calc(100%/3);
  height: 100%;
  border-radius: 2px;
  transition: transform 240ms;
  background-image: linear-gradient(to bottom, rgb(var(--skin-color-17)), rgb(var(--skin-color-18)) 32%, rgb(var(--hero-color)) 99%);
  [dir="ltr"] & {
    left: calc(-100%/3);
  }
  [dir="rtl"] & {
    right: calc(-100%/3);
  }
}
.bottom-bottom-inside-game-list-container{
  width: 100%;
  height: 100%;
  position: relative;
}
.teams-info-wr{
  width: 100%;
}
.teams-info-row{
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 8px 14px 0 14px;
}
.inside-teams-info-row{
  display: table-cell;
  vertical-align: top;
  padding: 0 4px;
  &.active{
    >.inside-teams-info-row-txt{
      color: rgb(var(--text-color));
    }
  }
  &.center{
    width: 40px;
    text-align: center;
    padding: 0;
    >span{
      word-break: break-all;
    }
  }
  &:first-child{
    [dir="ltr"] & {
      padding-right: 5%;
      text-align: right;
    }
    [dir="rtl"] & {
      padding-left: 5%;
      text-align: left;
    }
  }
  &:last-child{
    [dir="ltr"] & {
      padding-left: 5%;
      text-align: left;
    }
    [dir="rtl"] & {
      padding-right: 5%;
      text-align: right;
    }
  }
}
.inside-teams-info-row-txt{
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 20px;
  color: rgba(var(--text-color),0.5);
  text-transform: capitalize;
  transition: color 240ms;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.active {
    color: rgb(var(--text-color));
  }
}
.inside-teams-info-row-date-txt{
  width: 100%;
  font-size: 9px;
  letter-spacing: 0.69px;
  line-height: 12px;
  color: rgba(var(--text-color),0.5);
  text-transform: capitalize;
}
.bottom-inside-for-bottom-of-inside-game-list-history-wr{
  grid-area: bottom;
  position: relative;
}
.team-choose-bottom-b-img{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 0;
}
.team-choose-bottom-item-img{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &:last-child{
    width: 40px;
  }
}
.team-choose-bottom-txt{
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  font-weight: 300;
  color: rgb(var(--text-color));
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bottom-right-img-b{
  width: 100%;
  height: 100%;
  position: relative;
  >img{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
    [dir="ltr"] & {
      right: 0;
    }
    [dir="rtl"] & {
      left: 0;
    }
  }
}
.bottom-game-list-wrapper{
  z-index: 1;
  width: 100%;
  height: 46px;
  background-color: rgba(var(--skin-color-white), 0.06);
  position: relative;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
  &.active{
    [dir="ltr"] & {
      .inside-bottom-wr-bottom-game-list{
        transform: translateX(50%);
      }
    }
    [dir="rtl"] & {
        .inside-bottom-wr-bottom-game-list{
          transform: translateX(-50%);
        }
    }
  }
}
.inside-bottom-wr-bottom-game-list{
  display: grid;
  grid-template-columns: repeat(2,6fr);
  grid-template-rows: auto;
  grid-gap: 0;
  width: 200%;
  height: 100%;
  position: absolute;
  top: 0;
  transition: transform 240ms;
  [dir="ltr"] & {
    right: 0;
  }
  [dir="rtl"] & {
    left: 0;
  }
}
.teams-remove-block-bottom{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 14px;
}
.teams-remove-block-bottom-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &:first-child{
    .teams-remove-block-bottom-i-img-block{
      >img{
        [dir="ltr"] & {
          left: 0;
        }
        [dir="rtl"] & {
          right: 0;
        }

      }
    }
  }
  &:nth-child(2):nth-last-child(2){
    text-align: center;
  }
  &:last-child{
    .teams-remove-block-bottom-i-img-block{
      >img{
        [dir="ltr"] & {
          right: 0;
        }
        [dir="rtl"] & {
          left: 0;
        }
      }
    }
  }
}
.teams-remove-block-bottom-i-img-block{
  width: 100%;
  height: 100%;
  position: relative;
  >img{
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    max-width: 100%;
    max-height: 100%;
    cursor: pointer;
  }
}
.teams-choose-block-bottom{
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  padding: 8px 14px;
}
.teams-choose-block-bottom-item{
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  &:first-child{
    padding-right: 4px;
  }
  &:last-child{
    width: 40px;
    text-align: right;
  }
}
.teams-choose-block-bottom-item-txt{
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.3px;
  font-weight: 300;
  color: rgb(var(--text-color));
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/************************************************last-game styles END**********************/
/**************************************************list-item-another-styles START**************/
.for-change{
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  z-index: 50;
  opacity: 0;
  max-width: 0;
  max-height: 0;
  border-radius: 8px;
  overflow: hidden;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-6),0.62), rgba(var(--skin-color-13),0.62));
}
.center-circle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  transform: scale(0);
  border-radius: 50%;
  background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.62), rgba(#b1b2db,0.62));
  cursor: pointer;
  >span{
    font-size: 34px;
    color: rgb(var(--text-color));
  }
}

/**************************************************list-item-another-styles END**************/
.bottom-of-center-block{
  grid-area: bottom;
  position: relative;
  // overflow: hidden;
}
.buttons-wr{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  padding: 0 24px;
  margin-top: 28px;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-black), 0.15);
  background-color: rgba(var(--skin-color-white), 0.08);
  border-radius: 8px;
}
.button-block{
  flex: 0 0 auto;
  position: relative;
}
.game-list-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: none;
  outline: none;
  border-radius: 6px;
  background-color: rgba(var(--skin-color-white), 0.1);
  box-shadow: 0 2px 4px 0 rgba(var(--skin-color-white),0.075) inset;
  cursor: pointer;
  span{
    font-size: 22px;
    color: rgba(var(--text-color),1);
  }
  &:hover{
    background-color: rgba(var(--skin-color-white), 0.2);
  }
  &.inactive{
    opacity: 0.5;
    pointer-events: none;
  }
}
.bet-btn-holder{
  position: relative;
  width: 100%;
  max-width: 296px;
  margin: 0 12px;
  &.hide-bet-btn{
    display: none;
  }
  &.bet-btn-disabled  .bet-btn {
    pointer-events: none;
  }
}
.bet-btn{
  width: 100%;
  height: 42px;
  border-radius: 7px;
  padding: 0 6px;
  //background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.8), rgba(var(--skin-color-22),0.8) 83%);
  background-color: rgba(var(--brand-color-1), 0.8);
  font-size: 13px;
  letter-spacing: 1px;
  color: rgba(var(--brand-text-color),1);
  text-transform: uppercase;
  border: none;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  direction: ltr;
  &:hover{
    //background-image: linear-gradient(to top, rgba(var(--brand-color-1), 0.6), rgba(var(--skin-color-22),0.6) 83%);
    background-color: rgba(var(--brand-color-1), 0.6);
  }
}

@keyframes blinking {
  0% {
    color: #000000
  }
  100% {
    color: #ffffff
  }
}

.blinking {
  animation: blinking 1s infinite;
}





//Tooltip START
// .tooltip-container{
//   position: absolute;
//   z-index: 990;
//   top: 0;
//   left: 0;
//   &.cover{
//     width: 100%;
//     height: 100%;
//   }
// }
.tooltip-wr{
  opacity: 0;
  z-index: 990;
  cursor: default;
  position: absolute;
  font-family: $font-f;
  text-transform: none;
  backface-visibility: hidden;
  animation: tooltip-show 360ms forwards;
  @keyframes tooltip-show {
    to {
      opacity: 1;
    }
  }
  &.top-center{
    padding-top: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }
  &.top-right{
    padding-top: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      top: 0;
      right: 12px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }
  &.top-left{
    padding-top: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      top: 0;
      left: 12px;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }
  &.right-top{
    padding-right: 12px;
    .where-from{
      width: 12px;
      height: 26px;
      top: 12px;
      left: 100%;
      transform: translate(-100%, 0);
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }
  &.right-bottom{
    padding-right: 12px;
    .where-from{
      width: 12px;
      height: 26px;
      bottom: 12px;
      left: 100%;
      transform: translate(-100%, 0);
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
      -webkit-clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }
  &.left-top{
    padding-left: 12px;
    .where-from{
      width: 12px;
      height: 26px;
      top: 12px;
      left: 0;
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
      -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }
  &.left-bottom{
    padding-left: 12px;
    .where-from{
      width: 12px;
      height: 26px;
      bottom: 12px;
      left: 0;
      clip-path: polygon(100% 0, 0 50%, 100% 100%);
      -webkit-clip-path: polygon(100% 0, 0 50%, 100% 100%);
    }
  }
  &.bottom-center{
    padding-bottom: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
  &.bottom-right{
    padding-bottom: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      bottom: 0;
      right: 12px;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
  &.bottom-left{
    padding-bottom: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      bottom: 0;
      left: 12px;
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
  &.bottom-right-center{
    padding-bottom: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      bottom: 0;
      left: calc(65% - 40px);
      transform: translate(100%, 0%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
  &.bottom-left-center{
    padding-bottom: 12px;
    .where-from{
      width: 26px;
      height: 12px;
      bottom: 0;
      left: calc(35% - 40px);
      transform: translate(100%, 0%);
      clip-path: polygon(100% 0, 0 0, 50% 100%);
      -webkit-clip-path: polygon(100% 0, 0 0, 50% 100%);
    }
  }
}
.where-from{
  position: absolute;
  background-color: rgba(var(--skin-color-white), 0.5);
  border-radius: 2px;
}
.tooltip-inner{
  display: flex;
  flex-direction: column;
  width: 280px;
  min-height: 150px;
  border-radius: 6px;
  box-shadow: -1px 3px 8px 0 rgba(var(--skin-color-black), 0.29);
  background-image: linear-gradient(303deg, rgba(var(--skin-color-14),1) 2%, rgba(var(--global-color),1) 86%);
  overflow: hidden;
  position: relative;
  &:before{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(var(--skin-color-white),0.4), rgba(var(--skin-color-white), 0.1));
  }
}
.tooltip-inner-top{
  flex: 0 0 auto;
  position: relative;
  z-index: 10;
}
.inner-tooltip-inner-top{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: 40px;
  border-radius: 6px 6px 0 0;
  background-image: linear-gradient(260deg, rgba(var(--skin-color-14),0.4) 12%, rgba(var(--global-color),0.4) 96%);
}
.inner-tooltip-inner-top-left{
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  overflow: hidden;
}
.inner-tooltip-inner-top-left-txt{
  max-width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: rgba(var(--text-color), 1);
  text-transform: capitalize;
  line-height: 20px;
  letter-spacing: .47px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  [dir="ltr"] & {
    padding-left: 10px;
  }
  [dir="rtl"] & {
    padding-right: 10px;
  }
}
.inner-tooltip-inner-top-right{
  flex: 0 0 auto;
  height: 100%;
}
.close-icon-block{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 100%;
  cursor: pointer;
  >span{
    font-size: 8px;
    color: rgba(var(--text-color),1);
    transition: color 240ms;
  }
  &:hover{
    >span{
      color: rgba(var(--text-color),0.7);
    }
  }
}
.tooltip-inner-bottom{
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.top-tooltip-inner-bottom{
  flex: 1;
}
.top-tooltip-inner-bottom-txt{
  text-align: left;
  padding: 10px 10px 0 10px;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: rgba(var(--text-color),1);
  [dir="ltr"] & {
    text-align: left;
  }
  [dir="rtl"] & {
    text-align: right;
  }
}
.bottom-tooltip-inner-bottom-inner{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 36px;
  direction: ltr;
}
.next-prev-icon-block{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 100%;
  cursor: pointer;
  >span{
    font-size: 20px;
    color: rgba(var(--text-color),1);
    transition: color 240ms;
  }
  &:hover{
    >span{
      color: rgba(var(--text-color),0.7);
    }
  }
  &.disabled{
    cursor: default;
    >span{
      color: rgba(var(--text-color),0.3);
    }
  }
}

.tooltip-disabled {
  cursor: default;
  pointer-events: none;
}
//Tooltip END

