//@import '../../styles/constants';
$version-control: '?v=#{random(888)}';
//Font Start--------------------------------------------
$font-f: Poppins, Arial, Helvetica, sans-serif !default;
//Font END----------------------------------------------

$version-control: '?v=#{random(888)}';
@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  font-style: normal;
  src: local('PoppinsLight'),
  url('../default/PoppinsLight.eot#{$version-control}'),
  url('../default/PoppinsLight.eot#{$version-control}') format('embedded-opentype'),
  url('../default/PoppinsLight.woff2#{$version-control}') format('woff2'),
  url('../default/PoppinsLight.woff#{$version-control}') format('woff'),
  url('../default/PoppinsLight.ttf#{$version-control}') format('truetype'),
  url('../default/PoppinsLight.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  font-style: normal;
  src: local('PoppinsMedium'),
  url('../default/PoppinsMedium.eot#{$version-control}'),
  url('../default/PoppinsMedium.eot#{$version-control}') format('embedded-opentype'),
  url('../default/PoppinsMedium.woff2#{$version-control}') format('woff2'),
  url('../default/PoppinsMedium.woff#{$version-control}') format('woff'),
  url('../default/PoppinsMedium.ttf#{$version-control}') format('truetype'),
  url('../default/PoppinsMedium.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  font-style: normal;
  src: local('PoppinsSemiBold'),
  url('../default/PoppinsSemiBold.eot#{$version-control}'),
  url('../default/PoppinsSemiBold.eot#{$version-control}') format('embedded-opentype'),
  url('../default/PoppinsSemiBold.woff2#{$version-control}') format('woff2'),
  url('../default/PoppinsSemiBold.woff#{$version-control}') format('woff'),
  url('../default/PoppinsSemiBold.ttf#{$version-control}') format('truetype'),
  url('../default/PoppinsSemiBold.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  font-style: normal;
  src: local('PoppinsBold'),
  url('../default/PoppinsBold.eot#{$version-control}'),
  url('../default/PoppinsBold.eot#{$version-control}') format('embedded-opentype'),
  url('../default/PoppinsBold.woff2#{$version-control}') format('woff2'),
  url('../default/PoppinsBold.woff#{$version-control}') format('woff'),
  url('../default/PoppinsBold.ttf#{$version-control}') format('truetype'),
  url('../default/PoppinsBold.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  font-style: normal;
  src: local('PoppinsExtraBold'),
  url('../default/PoppinsExtraBold.eot#{$version-control}'),
  url('../default/PoppinsExtraBold.eot#{$version-control}') format('embedded-opentype'),
  url('../default/PoppinsExtraBold.woff2#{$version-control}') format('woff2'),
  url('../default/PoppinsExtraBold.woff#{$version-control}') format('woff'),
  url('../default/PoppinsExtraBold.ttf#{$version-control}') format('truetype'),
  url('../default/PoppinsExtraBold.svg#{$version-control}') format('svg');
  font-display: fallback;
}
@font-face {
  font-family: 'BetConstruct-Icons';
  src:  url('../../icons/general/fonts/BetConstruct-Icons.eot#{$version-control}');
  src:  url('../../icons/general/fonts/BetConstruct-Icons.eot#{$version-control}') format('embedded-opentype'),
  url('../../icons/general/fonts/BetConstruct-Icons.ttf#{$version-control}') format('truetype'),
  url('../../icons/general/fonts/BetConstruct-Icons.woff#{$version-control}') format('woff'),
  url('../../icons/general/fonts/BetConstruct-Icons.svg#{$version-control}') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="bc-i-"], [class*=" bc-i-"] {
  font-family: 'BetConstruct-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
