@font-face {
  font-family: 'BetConstruct-Icons';
  src:  url('fonts/BetConstruct-Icons.eot?vxvneo');
  src:  url('fonts/BetConstruct-Icons.eot?vxvneo#iefix') format('embedded-opentype'),
    url('fonts/BetConstruct-Icons.ttf?vxvneo') format('truetype'),
    url('fonts/BetConstruct-Icons.woff?vxvneo') format('woff'),
    url('fonts/BetConstruct-Icons.svg?vxvneo#BetConstruct-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="bc-i-"], [class*=" bc-i-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'BetConstruct-Icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bc-i-predictor-icon:before {
  content: "\e946";
}
.bc-i-clear-icon:before {
  content: "\e947";
}
.bc-i-icons-01:before {
  content: "\e943";
}
.bc-i-icons-02:before {
  content: "\e944";
}
.bc-i-icons-03:before {
  content: "\e945";
}
.bc-i-arrows:before {
  content: "\e941";
}
.bc-i-device:before {
  content: "\e942";
}
.bc-i-flash:before {
  content: "\e93f";
}
.bc-i-clockwise-circular-arrow-copy:before {
  content: "\e940";
}
.bc-i-predictor-snow-icon-01:before {
  content: "\e93e";
}
.bc-i-icon-accept:before {
  content: "\e93c";
}
.bc-i-icon-error:before {
  content: "\e93d";
}
.bc-i-error:before {
  content: "\e93b";
}
.bc-i-share-alt:before {
  content: "\e939";
}
.bc-i-lock:before {
  content: "\e93a";
}
.bc-i-dollar:before {
  content: "\e938";
}
.bc-i-hourglass-end:before {
  content: "\e934";
}
.bc-i-icon1:before {
  content: "\e935";
}
.bc-i-awards:before {
  content: "\e936";
}
.bc-i-home:before {
  content: "\e937";
}
.bc-i-arrow:before {
  content: "\e930";
}
.bc-i-flip:before {
  content: "\e931";
}
.bc-i-noun-ban:before {
  content: "\e932";
}
.bc-i-plus:before {
  content: "\e933";
}
.bc-i-statistic:before {
  content: "\e900";
}
.bc-i-close:before {
  content: "\e901";
}
.bc-i-done:before {
  content: "\e902";
}
.bc-i-full-screen:before {
  content: "\e903";
}
.bc-i-icon:before {
  content: "\e904";
}
.bc-i-icon-copy:before {
  content: "\e905";
}
.bc-i-info:before {
  content: "\e906";
}
.bc-i-prizes:before {
  content: "\e907";
}
.bc-i-information:before {
  content: "\e927";
}
.bc-i-info1:before {
  content: "\e926";
}
.bc-i-corner:before {
  content: "\e921";
}
.bc-i-stream:before {
  content: "\e922";
}
.bc-i-card-football:before {
  content: "\e923";
}
.bc-i-half-time:before {
  content: "\e924";
}
.bc-i-team-t-shirt:before {
  content: "\e925";
}
.bc-i-graph:before {
  content: "\e91f";
}
.bc-i-head-t-head:before {
  content: "\e920";
}
.bc-i-field-soccer:before {
  content: "\e91a";
}
.bc-i-result:before {
  content: "\e91b";
}
.bc-i-field-basketball:before {
  content: "\e91c";
}
.bc-i-burger-2:before {
  content: "\e91d";
}
.bc-i-search:before {
  content: "\e91e";
}
.bc-i-balance:before {
  content: "\e908";
}
.bc-i-wallet:before {
  content: "\e918";
}
.bc-i-user:before {
  content: "\e919";
}
.bc-i-favorite:before {
  content: "\e838";
}
.bc-i-favorite-unselected:before {
  content: "\e83a";
}
.bc-i-streaming:before {
  content: "\e916";
}
.bc-i-statistics:before {
  content: "\e917";
}
.bc-i-facebook:before {
  content: "\e913";
}
.bc-i-linkedin:before {
  content: "\e914";
}
.bc-i-twitter:before {
  content: "\e915";
}
.bc-i-android:before {
  content: "\e910";
}
.bc-i-app-store:before {
  content: "\e911";
}
.bc-i-mobile:before {
  content: "\e912";
}
.bc-i-draw:before {
  content: "\e90f";
}
.bc-i-radio-selected:before {
  content: "\e90d";
}
.bc-i-radio-unselected:before {
  content: "\e90e";
}
.bc-i-eye-filled:before {
  content: "\e909";
}
.bc-i-eye:before {
  content: "\e90c";
}
.bc-i-time:before {
  content: "\e8b5";
}
.bc-i-call:before {
  content: "\e90a";
}
.bc-i-casino:before {
  content: "\e90b";
}
.bc-i-live-chat:before {
  content: "\e928";
}
.bc-i-burger-menu:before {
  content: "\e929";
}
.bc-i-message:before {
  content: "\e92a";
}
.bc-i-notification:before {
  content: "\e92b";
}
.bc-i-promotion:before {
  content: "\e92c";
}
.bc-i-settings:before {
  content: "\e92d";
}
.bc-i-swatches:before {
  content: "\e92e";
}
.bc-i-pause:before {
  content: "\e034";
}
.bc-i-play:before {
  content: "\e037";
}
.bc-i-stop:before {
  content: "\e047";
}
.bc-i-checked:before {
  content: "\e5ca";
}
.bc-i-open-with:before {
  content: "\e89f";
}
.bc-i-settings1:before {
  content: "\e92f";
}
.bc-i-wallet1:before {
  content: "\e850";
}
.bc-i-apps:before {
  content: "\e5c3";
}
.bc-i-config:before {
  content: "\e869";
}
.bc-i-duplicate:before {
  content: "\e3e0";
}
.bc-i-add:before {
  content: "\e145";
}
.bc-i-remove:before {
  content: "\e15b";
}
.bc-i-check-box-checked:before {
  content: "\e834";
}
.bc-i-check-box-unchecked:before {
  content: "\e835";
}
.bc-i-arrow-back:before {
  content: "\e5c4";
}
.bc-i-arrow-forward:before {
  content: "\e5c8";
}
.bc-i-arrow-downward:before {
  content: "\e5db";
}
.bc-i-arrow-upward:before {
  content: "\e5d8";
}
.bc-i-small-arrow-left:before {
  content: "\e314";
}
.bc-i-small-arrow-right:before {
  content: "\e315";
}
.bc-i-small-arrow-down:before {
  content: "\e5cf";
}
.bc-i-small-arrow-up:before {
  content: "\e5ce";
}
.bc-i-arrow-drop-down-left:before {
  content: "\e5c5";
}
.bc-i-arrow-drop-down-right:before {
  content: "\e5c6";
}
.bc-i-arrow-drop-down-bottom:before {
  content: "\e5c7";
}
.bc-i-arrow-drop-down-up:before {
  content: "\e5c9";
}
.bc-i-close-remove:before {
  content: "\e5cd";
}
.bc-i-edit-mode:before {
  content: "\e254";
}
.bc-i-burger:before {
  content: "\e3c7";
}
.bc-i-star:before {
  content: "\f005";
}
.bc-i-trophy:before {
  content: "\f091";
}
