
//Moved to _default.scss START -------------------------
// $version-control: '?v=#{random(888)}';
//Font Start--------------------------------------------
// $font-f: Poppins, Arial, Helvetica, sans-serif !default;
//Font END----------------------------------------------
//Moved to _default.scss End ---------------------------

//Skin Colors Start-------------------------------------
$global-color: #291E39  !default; //#3B3790 41, 30, 57
$hero-color: #bd1590    !default; //#bd1590
$brand-color-1: #2b928a !default; //#2b928a
$brand-color-2: #fba429 !default; //#fba429

$line-color: rgba(#a61f67, 1);
$scroll-color: rgba(#41245e, 1);
$ticket-bg-color: rgba(#494057, 1);
$ticket-header-color: rgba(#544b61, 1);
$info-row-color: rgba(#41374f, 1);
$rd-header-color: rgba(#4f465c, 1);
$rd-body-color: rgba(#41374f, 1);
$rd-name-color: rgba(#FFFFFF, 1);
$rd-tb-bg-color: rgba(#3b314a, 1);
$loader-color: rgba(#bd1590, 1);
//Skin colors END---------------------------------------

//Text colors Start-------------------------------------
$text-color: #FFFFFF;
//Text colors END---------------------------------------

//Status colors Start-----------------------------------
$status-color-1: #2b928a  !default; //#2b928a
$status-color-2: #fba429  !default; //#fba429
$status-color-3: #a40c1f  !default; //#a40c1f
//Status colors END-------------------------------------

//img filter Stat
$filter: invert(0) !default;
//img filter End

//background colors start--------------------------------------------------------------------------

$skin-color-black: #000000 !default;
$skin-color-white: #FFFFFF !default;
@function set-color($color, $color-dark: #000000, $color-light: #FFFFFF) {
  @if (lightness($color) > 50) {
    @return $color-dark;
  } @else {
    @return $color-light;
  }
}
$skin-color-1: darken(saturate($global-color, 15.74), 22.16)                    !default; //#131145
$skin-color-2: darken(saturate(adjust-hue($global-color, -10), 7.74), 15.10)    !default; //#1d255d
$skin-color-3: darken(saturate(adjust-hue($global-color, -7), 7.95), 13.33)     !default; //#1f2464
$skin-color-4: darken(saturate(adjust-hue($global-color, -8), 5.28), 10.78)     !default; //#242a6c
$skin-color-5: darken(saturate(adjust-hue($global-color, -10), 1.85), 10.39)    !default; //#272F6B
$skin-color-6: darken(saturate(adjust-hue($global-color, -5), 2.64), 9.22)      !default; //#282b70
//$skin-color-7: $global-color; //#3B3790
$skin-color-8: darken(desaturate(adjust-hue($global-color, 12), 0.28), 0.20)    !default; //#4D378F
$skin-color-9:  lighten(saturate(adjust-hue($global-color, 12), 1.68), 11.96)   !default; //#6448bc
$skin-color-10: lighten(desaturate(adjust-hue($global-color, 3), 3.69), 11.76)  !default; //#584EB5
$skin-color-11: lighten(desaturate(adjust-hue($global-color, -4), 0.79), 14.12) !default; //#5355bc
$skin-color-12: lighten(saturate(adjust-hue($global-color, 3), 1.46), 12.16)    !default; //#5449bc
$skin-color-13: lighten(desaturate(adjust-hue($global-color, -7), 5.41), 15.10) !default; //#5C63B8
$skin-color-14: lighten(saturate(adjust-hue($global-color, -7), 3.85), 26.67)   !default; //#7d83d2
$skin-color-15: lighten(saturate(adjust-hue($global-color, 9), 55.28), 42.16)   !default; //#B29FFF
$skin-color-16: lighten(saturate(adjust-hue(#3b3790, 86), 55.28), 56.08)        !default; //#ffe6f3
$skin-color-17: lighten(saturate($hero-color, 11.15), 36.67) !default;
$skin-color-18: lighten(desaturate($hero-color, 9.38), 24.12) !default;
$skin-color-19: lighten(saturate($status-color-1, 45.50), 37.65) !default;
$skin-color-20: lighten(saturate(adjust-hue($status-color-2, 10), 3.67), 14.51) !default;
$skin-color-21: lighten(saturate(adjust-hue($status-color-3, -3), 13.64), 28.63) !default;
$skin-color-22: lighten(saturate($brand-color-1, 45.50), 37.65) !default;

@if (lightness($global-color) > 45) {
  $text-color: #000000;
  $skin-color-black: #FFFFFF;
  $skin-color-white: #000000;
  $filter: invert(1);
}
@if (lightness($global-color) > 95) {
  $global-color: darken($global-color, 40);
  $skin-color-1: darken(saturate($global-color, 4.74), 22.16);
  $skin-color-2: darken(saturate(adjust-hue($global-color, -10), 7.74), 15.10);
  $skin-color-3: darken(saturate(adjust-hue($global-color, -7), 7.95), 13.33);
  $skin-color-4: darken(saturate(adjust-hue($global-color, -8), 5.28), 10.78);
  $skin-color-5: darken(saturate(adjust-hue($global-color, -10), 1.85), 10.39);
  $skin-color-6: darken(saturate(adjust-hue($global-color, -5), 2.64), 9.22);
  //$skin-color-7: $global-color;
  $skin-color-8: darken(saturate(adjust-hue($global-color, -5), 2.64), 9.22);
  $skin-color-9: darken(saturate(adjust-hue($global-color, -10), 1.85), 10.39);
  $skin-color-10: darken(saturate(adjust-hue($global-color, -8), 5.28), 10.78);
  $skin-color-11: darken(saturate(adjust-hue($global-color, -8), 5.28), 10.78);
  $skin-color-12: darken(saturate(adjust-hue($global-color, -7), 7.95), 13.33);
  $skin-color-13: darken(saturate(adjust-hue($global-color, -10), 7.74), 15.10);
  $skin-color-14: darken(saturate($global-color, 6.74), 22.16);
  $skin-color-15: darken(saturate($global-color, 4.74), 24.16);
  $skin-color-16: darken(saturate($global-color, 1.74), 16.16);
}
//background colors END------------------------------------------------------------------

:root {
  --global-color:#{red($global-color), green($global-color), blue($global-color)};
  --hero-color: #{red($hero-color), green($hero-color), blue($hero-color)};
  --brand-color-1: #{red($brand-color-1), green($brand-color-1), blue($brand-color-1)};
  --brand-color-2: #{red($brand-color-2), green($brand-color-2), blue($brand-color-2)};
  --text-color: #{red($text-color), green($text-color), blue($text-color)};
  --status-color-1:#{red($status-color-1), green($status-color-1), blue($status-color-1)};
  --status-color-2:#{red($status-color-2), green($status-color-2), blue($status-color-2)};
  --status-color-3:#{red($status-color-3), green($status-color-3), blue($status-color-3)};
  --skin-color-black: #{red($skin-color-black), green($skin-color-black), blue($skin-color-black)};
  --skin-color-white: #{red($skin-color-white), green($skin-color-white), blue($skin-color-white)};
  --skin-color-1:#{red($skin-color-1), green($skin-color-1), blue($skin-color-1)};
  --skin-color-2:#{red($skin-color-2), green($skin-color-2), blue($skin-color-2)};
  --skin-color-3:#{red($skin-color-3), green($skin-color-3), blue($skin-color-3)};
  --skin-color-4:#{red($skin-color-4), green($skin-color-4), blue($skin-color-4)};
  --skin-color-5:#{red($skin-color-5), green($skin-color-5), blue($skin-color-5)};
  --skin-color-6:#{red($skin-color-6), green($skin-color-6), blue($skin-color-6)};
  //--skin-color-7:#{red($global-color), green($global-color), blue($global-color)};
  //--skin-color-7: 23, 28, 44;
  --skin-color-8:#{red($skin-color-8), green($skin-color-8), blue($skin-color-8)};
  --skin-color-9:#{red($skin-color-9), green($skin-color-9), blue($skin-color-9)};
  --skin-color-10: #{red($skin-color-10), green($skin-color-10), blue($skin-color-10)};
  --skin-color-11: #{red($skin-color-11), green($skin-color-11), blue($skin-color-11)};
  --skin-color-12: #{red($skin-color-12), green($skin-color-12), blue($skin-color-12)};
  --skin-color-13: #{red($skin-color-13), green($skin-color-13), blue($skin-color-13)};
  --skin-color-14: #{red($skin-color-14), green($skin-color-14), blue($skin-color-14)};
  --skin-color-15: #{red($skin-color-15), green($skin-color-15), blue($skin-color-15)};
  --skin-color-16: #{red($skin-color-16), green($skin-color-16), blue($skin-color-16)};
  --skin-color-17: #{red($skin-color-17), green($skin-color-17), blue($skin-color-17)};
  --skin-color-18: #{red($skin-color-18), green($skin-color-18), blue($skin-color-18)};
  --skin-color-19: #{red($skin-color-19), green($skin-color-19), blue($skin-color-19)};
  --skin-color-20: #{red($skin-color-20), green($skin-color-20), blue($skin-color-20)};
  --skin-color-21: #{red($skin-color-21), green($skin-color-21), blue($skin-color-21)};
  --skin-color-22: #{red($skin-color-22), green($skin-color-22), blue($skin-color-22)};

  --line-color: #{red($line-color), green($line-color), blue($line-color)};
  --scroll-color: #{red($scroll-color), green($scroll-color), blue($scroll-color)};
  --ticket-bg-color: #{red($ticket-bg-color), green($ticket-bg-color), blue($ticket-bg-color)};
  --ticket-header-color: #{red($ticket-header-color), green($ticket-header-color), blue($ticket-header-color)};
  --info-row-color: #{red($info-row-color), green($info-row-color), blue($info-row-color)};
  --rd-header-color: #{red($rd-header-color), green($rd-header-color), blue($rd-header-color)};
  --rd-body-color: #{red($rd-body-color), green($rd-body-color), blue($rd-body-color)};
  --rd-name-color: #{red($rd-name-color), green($rd-name-color), blue($rd-name-color)};
  --rd-tb-bg-color: #{red($rd-tb-bg-color), green($rd-tb-bg-color), blue($rd-tb-bg-color)};
  --loader-color: #{red($loader-color), green($loader-color), blue($loader-color)};

  --filter: invert(0);
}

