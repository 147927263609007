html{
  width: 100%;
  height: 100%;
  background-color: rgb(var(--global-color));

  -webkit-tap-highlight-color: transparent;
}
body{
  width: 100%;
  height: 100%;
  font-family: $font-f;
  font-weight: 400;
  color: rgb(var(--text-color));
  user-select: none;
  -webkit-overflow-scrolling: touch;
  //background: url("../images/bg-repeat-img.png") repeat;
}

//html, body {
//  -webkit-overflow-scrolling : touch !important;
//  overflow: auto !important;
//  height: 100% !important;
//}

.root{
  width: 100%;
  height: 100%;
}

.main-wrapper{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 10fr auto;
  grid-template-areas: "header" "content" "footer";
  grid-gap: 0;
  width: 100%;
  max-width: 1464px;
  height: 100%;
  padding: 28px;
  overflow: hidden;
  margin: 0 auto;
}
.mobile-nav{
  display: none;
}
.header{
  grid-area: header;
}
.content{
  grid-area: content;
  position: relative;
}
.footer{
  grid-area: footer;
  position: relative;
}
.content-inside-wrapper{
  display: grid;
  grid-template-columns: auto 10fr auto;
  grid-template-rows: 12fr;
  grid-template-areas: "left center right";
  grid-row-gap: 0;
  grid-column-gap: 20px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  [dir="ltr"] & {
    left: 0;
  }
  [dir="rtl"] & {
    right: 0;
  }
}
.left-column{
  grid-area: left;
  position: relative;
}
.center-column{
  grid-area: center;
  position: relative;
}
.right-column{
  grid-area: right;
  position: relative;
}
.left-container-wr{
  width: 344px;
  height: 100%;
  padding: 0 16px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-1), 0.2);
  //background-color: rgba(var(--skin-color-white), 0.08);
  background-color: rgba(var(--rd-tb-bg-color), 1);
}
.right-container-wr{
  width: 328px;
  height: 100%;
  padding: 0 16px 16px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0 5px 9px 2px rgba(var(--skin-color-1), 0.2);
  //background-color: rgba(var(--skin-color-white), 0.08);
  background-color: rgba(var(--rd-tb-bg-color), 1);
}
.scroll-wr{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  //scrollbar-color: rgba(var(--skin-color-white), 0.7) rgba(var(--skin-color-15), 0.2);
  scrollbar-color: rgba(var(--scroll-color), 0.7) rgba(var(--scroll-color), 0.2);
  scrollbar-width: thin;
}
.skeleton{
  position: relative;
  pointer-events: none;
  color: transparent;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(90deg, transparent, rgba(var(--skin-color-white), 0.4), transparent);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    animation: progress 960ms ease-in-out forwards;
    overflow: hidden;
  }
}
@keyframes progress{
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(100%, 0, 0);
  }
}
